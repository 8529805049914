import { gql } from "graphql-request"
import React, { FC } from "react"
import {
  AutocompleteInput,
  BooleanField,
  Button,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  FormDataConsumer,
  List,
  NumberField,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin"

import { PurchaseStatus } from "./generated/graphql"
import { EditToolbar, SearchFilter } from "./utils"
import { client as graphQLClient } from "./utils/api"

const purchaseStatusChoices = [
  ...Object.keys(PurchaseStatus).map((k) => ({
    id: k,
    name: k,
  })),
]

export const PurchaseCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput reference="User" source="owner">
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <ReferenceInput reference="Product" source="product">
        <AutocompleteInput optionText="code" />
      </ReferenceInput>
      <TextInput source="message" />
      <RadioButtonGroupInput choices={purchaseStatusChoices} source="status" />
    </SimpleForm>
  </Create>
)

export const PurchaseEdit: FC<any> = (props) => {
  const token = localStorage.getItem("token")
  const mutation = gql`
    mutation ReprocessPurchase($purchaseIds: String!) {
      reprocessPurchases(purchaseIds: $purchaseIds, notifyUser: true)
    }
  `
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const purchaseId = props["id"] as string

  const sendEmail = (purchaseId: string) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    void graphQLClient
      .setHeader("Authorization", token)
      .request(mutation, {
        purchaseIds: purchaseId,
      })
      .then(() => {
        alert("Email sent")
      })
      .catch(alert)
  }
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextField source="id" />
        <ReferenceInput reference="User" source="owner">
          <AutocompleteInput optionText="username" />
        </ReferenceInput>
        <ReferenceField reference="User" source="purchaserId">
          <ChipField source="username" />
        </ReferenceField>

        <TextInput source="message" />
        <NumberField source="price" />
        <NumberInput source="amount" />
        <NumberInput source="fee" />
        <NumberInput source="discount" />
        <TextField source="paymentMethod" />
        <RadioButtonGroupInput choices={purchaseStatusChoices} source="status" />
        <FormDataConsumer>
          {({ formData }) => {
            return (
              <>
                {
                  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
                  formData.status === PurchaseStatus.Refunded && (
                    <NumberInput source="refundedAmount" />
                  )
                }
              </>
            )
          }}
        </FormDataConsumer>
        <TextInput source="statusMessage" />
        <BooleanField source="fromWeb" />
        <BooleanField source="sendQR" />
        <BooleanField source="emailSent" />
        <Button label="Resend Email" onClick={() => sendEmail(purchaseId)} />
        <DateTimeInput allowEmpty showTime={true} source="usedAt" />
        <ReferenceField reference="ClaimedBadge" source="badge">
          <ChipField source="id" />
        </ReferenceField>
        <ReferenceInput reference="Product" source="product">
          <AutocompleteInput optionText="code" />
        </ReferenceInput>
        <ReferenceInput reference="Promocode" source="promocode">
          <AutocompleteInput optionText="code" />
        </ReferenceInput>
        <NumberInput source="swapPrice" />
        <DateField showTime={true} source="createdAt" />
      </SimpleForm>
    </Edit>
  )
}

export const PurchaseList: FC<any> = (props) => (
  <List
    {...props}
    filters={
      <SearchFilter {...props}>
        <TextInput label="PurchaseId" source="id" />
        <TextInput label="Status" source="status" />
        <TextInput label="Product" source="product.name" />
        <TextInput label="ProductId" source="product.id" />
        <TextInput label="Owner" source="owner.username" />
        <TextInput label="Payee" source="payee" />
      </SearchFilter>
    }
  >
    <Datagrid rowClick="edit">
      <ReferenceField reference="User" source="owner">
        <ChipField source="username" />
      </ReferenceField>
      <ReferenceField reference="Product" source="product">
        <ChipField source="code" />
      </ReferenceField>
      <TextField source="message" />
      <TextField source="payee" />
      <TextField source="status" />
      <ReferenceField reference="ClaimedBadge" source="badge">
        <ChipField source="id" />
      </ReferenceField>
      <BooleanField source="fromWeb" />
      <BooleanField source="sendQR" />
      <BooleanField source="hasBeenUsed" />
      <DateField showTime={true} source="createdAt" />
    </Datagrid>
  </List>
)
